import { NgModule } from "@angular/core";
import { FilterDevicePipe } from "./filterDevice.pipe";
import { SanitizePipe } from './SanitizePipe';

@NgModule({
  imports: [
    // dep modules
  ],
  declarations: [SanitizePipe, FilterDevicePipe],
  exports: [SanitizePipe, FilterDevicePipe]
})
export class PipesModule {}
