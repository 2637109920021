import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from '../_helpers/must-match.validators';
import { ServicesService } from '../core/Services/services.service';
import { ApiUrl } from '../core/api.url';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CustomPopUpComponent } from '../custom-pop-up/custom-pop-up.component';
import { TranslateService } from '@ngx-translate/core';
import { Select2OptionData } from 'ng-select2';
import * as CryptoJs from 'crypto-js';

@Component({
  selector: 'app-registertion',
  templateUrl: './registertion.component.html',
  styleUrls: ['./registertion.component.css']
})
export class RegistertionComponent implements OnInit {
  public exampleData: Array<Select2OptionData>;
  // title = 'ng-bootstrap-modal-demo';
  closeResult: string;
  modalOptions: NgbModalOptions;
  modalTermsOptions: NgbModalOptions;
  registerForm: FormGroup;
  invalid_email: boolean = false;
  emailExist: any;
  modal_message: string = '';
  isHide = true;
  isHidec = true;
  passwordClass = '';
  message;
  // modal_message2:any;
  submitted = false;
  ValidareData = {
    email: '',
    mainUnit: '',
    password: '',
    deviceToken: 'qwerty',
    country: '',
    deviceType: 'WEB',
    appType:'WEB'
  }
  data: any;
  content: any;
  response;
  policySection;
  errorMsg: [];
  constructor(private formBuilder: FormBuilder, 
    private RegistrationService: ServicesService, 
    private modalService: NgbModal,
    private translate: TranslateService,
    private PPolicyService:ServicesService
  ) {
    //this.open();
    this.createForm();
    this.modalTermsOptions = {
      centered: true,
      scrollable: true,
      size: 'xl'
    }
    this.modalOptions = {
      centered: true,
    }

    this.PPolicyService.getData(ApiUrl.privacyPolicy).subscribe(responseall =>
    {
      this.response = responseall;
      this.policySection = this.response.data[0].message;
    });
  }

  createForm() 
  {
    this.registerForm = this.formBuilder.group({
      email: ['', [Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
      mainUnit:['',Validators.required],
      password: ['', [Validators.required,Validators.minLength(6),Validators.maxLength(20)]],
      confirmPassword: ['', Validators.required],
      country: ['', Validators.required],
      termsandconditions: ['', Validators.requiredTrue]
    }, {
      validator: MustMatch('password', 'confirmPassword')
    })
  }

  resetForm() {
    this.registerForm.reset();
    this.submitted = false;
  }

  ngOnInit() {
    this.exampleData = [
      { text: 'Afghanistan', id: 'Afghanistan' },
      { text: 'Albania', id: 'Albania' },
      { text: 'Algeria', id: 'Algeria' },
      { text: 'Andorra', id: 'Andorra' },
      { text: 'Angola', id: 'Angola' },
      { text: 'Anguilla', id: 'Anguilla' },
      { text: 'Antarctica', id: 'AQ' },
      { text: 'Antigua and Barbuda', id: 'Antigua and Barbuda' },
      { text: 'Argentina', id: 'Argentina' },
      { text: 'Armenia', id: 'Armenia' },
      { text: 'Aruba', id: 'Aruba' },
      { text: 'Australia', id: 'Australia' },
      { text: 'Austria', id: 'Austria' },
      { text: 'Azerbaijan', id: 'Azerbaijan' },
      { text: 'Bahamas', id: 'Bahamas' },
      { text: 'Bahrain', id: 'Bahrain' },
      { text: 'Bangladesh', id: 'Bangladesh' },
      { text: 'Barbados', id: 'Barbados' },
      { text: 'Belarus', id: 'Belarus' },
      { text: 'Belgium', id: 'Belgium' },
      { text: 'Belize', id: 'Belize' },
      { text: 'Benin', id: 'Benin' },
      { text: 'Bermuda', id: 'Bermuda' },
      { text: 'Bhutan', id: 'Bhutan' },
      { text: 'Bolivia', id: 'Bolivia' },
      { text: 'Bosnia and Herzegovina', id: 'Bosnia and Herzegovina' },
      { text: 'Botswana', id: 'Botswana' },
      { text: 'Brazil', id: 'Brazil' },
      { text: 'British Virgin Islands', id: 'British Virgin Islands' },
      { text: 'Brunei', id: 'Brunei' },
      { text: 'Bulgaria', id: 'Bulgaria' },
      { text: 'Burkina Faso', id: 'Burkina Faso' },
      { text: 'Burundi', id: 'Burundi' },
      { text: 'Cambodia', id: 'Cambodia' },
      { text: 'Cameroon', id: 'Cameroon' },
      { text: 'Canada', id: 'Canada' },
      { text: 'Cape Verde', id: 'Cape Verde' },
      { text: 'Cayman Islands', id: 'Cayman Islands' },
      { text: 'Chad', id: 'Chad' },
      { text: 'Chile', id: 'Chile' },
      { text: 'China', id: 'China' },
      { text: 'Colombia', id: 'Colombia' },
      { text: 'Congo', id: 'Congo' },
      { text: 'Cook Islands', id: 'Cook Islands' },
      { text: 'Costa Rica', id: 'Costa Rica' },
      { text: 'Cote D Ivoire', id: 'Cote D Ivoire' },
      { text: 'Croatia', id: 'Croatia' },
      { text: 'Cuba', id: 'Cuba' },
      { text: 'Cyprus', id: 'Cyprus' },
      { text: 'Czech Republic', id: 'Czech Republic' },
      { text: 'Denmark', id: 'Denmark' },
      { text: 'Djibouti', id: 'Djibouti' },
      { text: 'Dominica', id: 'Dominica' },
      { text: 'Dominican Republic', id: 'Dominican Republic' },
      { text: 'Ecuador', id: 'Ecuador' },
      { text: 'Egypt', id: 'Egypt' },
      { text: 'El Salvador', id: 'El Salvador' },
      { text: 'Equatorial Guinea', id: 'Equatorial Guinea' },
      { text: 'Estonia', id: 'Estonia' },
      { text: 'Ethiopia', id: 'Ethiopia' },
      { text: 'Falkland Islands', id: 'Falkland Islands' },
      { text: 'Faroe Islands', id: 'Faroe Islands' },
      { text: 'Fiji', id: 'Fiji' },
      { text: 'Finland', id: 'Finland' },
      { text: 'France', id: 'France' },
      { text: 'French Polynesia', id: 'French Polynesia' },
      { text: 'French West Indies', id: 'French West Indies' },
      { text: 'Gabon', id: 'Gabon' },
      { text: 'Gambia', id: 'Gambia' },
      { text: 'Georgia', id: 'Georgia' },
      { text: 'Germany', id: 'Germany' },
      { text: 'Ghana', id: 'Ghana' },
      { text: 'Gibraltar', id: 'Gibraltar' },
      { text: 'Greece', id: 'Greece' },
      { text: 'Greenland', id: 'Greenland' },
      { text: 'Grenada', id: 'Grenada' },
      { text: 'Guam', id: 'Guam' },
      { text: 'Guatemala', id: 'Guatemala' },
      { text: 'Guernsey', id: 'Guernsey' },
      { text: 'Guinea', id: 'Guinea' },
      { text: 'Guinea Bissau', id: 'Guinea BissauW' },
      { text: 'Guyana', id: 'Guyana' },
      { text: 'Haiti', id: 'Haiti' },
      { text: 'Honduras', id: 'Honduras' },
      { text: 'Hong Kong', id: 'Hong Kong' },
      { text: 'Hungary', id: 'Hungary' },
      { text: 'Iceland', id: 'Iceland' },
      { text: 'India', id: 'India' },
      { text: 'Indonesia', id: 'Indonesia' },
      { text: 'Iran', id: 'Iran' },
      { text: 'Iraq', id: 'Iraq' },
      { text: 'Ireland', id: 'Ireland' },
      { text: 'Isle of Man', id: 'Isle of Man' },
      { text: 'Israel', id: 'Israel' },
      { text: 'Italy', id: 'Italy' },
      { text: 'Jamaica', id: 'Jamaica' },
      { text: 'Japan', id: 'Japan' },
      { text: 'Jersey', id: 'Jersey' },
      { text: 'Jordan', id: 'Jordan' },
      { text: 'Kazakhstan', id: 'Kazakhstan' },
      { text: 'Kenya', id: 'Kenya' },
      // {text: 'Kiribati', id: 'KI'}, 
      // {text: 'Korea, Democratic People\'S Republic of', id: 'KP'}, 
      // {text: 'Korea, Republic of', id: 'KR'}, 
      { text: 'Kuwait', id: 'Kuwait' },
      { text: 'Kyrgyz Republic', id: 'Kyrgyz Republic' },
      { text: 'Laos', id: 'Laos' },
      { text: 'Latvia', id: 'Latvia' },
      { text: 'Lebanon', id: 'Lebanon' },
      { text: 'Lesotho', id: 'Lesotho' },
      { text: 'Liberia', id: 'Liberia' },
      { text: 'Libya', id: 'Libya' },
      { text: 'Liechtenstein', id: 'Liechtenstein' },
      { text: 'Lithuania', id: 'Lithuania' },
      { text: 'Luxembourg', id: 'Luxembourg' },
      { text: 'Macau', id: 'Macau' },
      { text: 'Macedonia', id: 'Macedonia' },
      { text: 'Madagascar', id: 'Madagascar' },
      { text: 'Malawi', id: 'Malawi' },
      { text: 'Malaysia', id: 'Malaysia' },
      { text: 'Maldives', id: 'Maldives' },
      { text: 'Mali', id: 'Mali' },
      { text: 'Malta', id: 'Malta' },
      // {text: 'Marshall Islands', id: 'MH'}, 
      // {text: 'Martinique', id: 'MQ'}, 
      { text: 'Mauritania', id: 'Mauritania' },
      { text: 'Mauritius', id: 'Mauritius' },
      // {text: 'Mayotte', id: 'YT'}, 
      { text: 'Mexico', id: 'Mexico' },
      //{text: 'Micronesia, Federated States of', id: 'FM'}, 
      { text: 'Moldova', id: 'Moldova' },
      { text: 'Monaco', id: 'Monaco' },
      { text: 'Mongolia', id: 'Mongolia' },
      { text: 'Montenegro', id: 'Montenegro' },
      { text: 'Montserrat', id: 'Montserrat' },
      { text: 'Morocco', id: 'Morocco' },
      { text: 'Mozambique', id: 'Mozambique' },
      // {text: 'Myanmar', id: 'MM'}, 
      { text: 'Namibia', id: 'Namibia' },
      //{text: 'Nauru', id: 'NR'}, 
      { text: 'Nepal', id: 'Nepal' },
      { text: 'Netherlands', id: 'Netherlands' },
      { text: 'Netherlands Antilles', id: 'Netherlands Antilles' },
      { text: 'New Caledonia', id: 'New Caledonia' },
      { text: 'New Zealand', id: 'New Zealand' },
      { text: 'Nicaragua', id: 'Nicaragua' },
      { text: 'Niger', id: 'Niger' },
      { text: 'Nigeria', id: 'Nigeria' },
      // {text: 'Niue', id: 'NU'}, 
      // {text: 'Norfolk Island', id: 'NF'}, 
      // {text: 'Northern Mariana Islands', id: 'MP'}, 
      { text: 'Norway', id: 'Norway' },
      { text: 'Oman', id: 'Oman' },
      { text: 'Pakistan', id: 'Pakistan' },
      //{text: 'Palau', id: 'PW'}, 
      { text: 'Palestine', id: 'Palestine' },
      { text: 'Panama', id: 'Panama' },
      { text: 'Papua New Guinea', id: 'Papua New Guinea' },
      { text: 'Paraguay', id: 'Paraguay' },
      { text: 'Peru', id: 'Peru' },
      { text: 'Philippines', id: 'Philippines' },
      //{text: 'Pitcairn', id: 'PN'}, 
      { text: 'Poland', id: 'Poland' },
      { text: 'Portugal', id: 'Portugal' },
      { text: 'Puerto Rico', id: 'Puerto Rico' },
      { text: 'Qatar', id: 'Qatar' },
      { text: 'Reunion', id: 'Reunion' },
      { text: 'Romania', id: 'Romania' },
      { text: 'Russia', id: 'Russia' },
      { text: 'Rwanda', id: 'Rwanda' },
      // {text: 'Saint Helena', id: 'SH'}, 
      // {text: 'Saint Kitts and Nevis', id:this.invalid_email=false 'KN'}, 
      // {text: 'Saint Lucia', id: 'LC'}, this.invalid_email=false
      { text: 'Saint Pierre and Miquelon', id: 'Saint Pierre and Miquelon' },
      // {text: 'Saint Vincent and the Grenathis.invalid_email=falsedines', id: 'VC'}, 
      { text: 'Samoa', id: 'Samoa' },
      { text: 'San Marino', id: 'San Marino' },
      // {text: 'Sao Tome and Principe', id: 'ST'},
      { text: 'Satellite', id: 'Satellite' },
      { text: 'Saudi Arabia', id: 'Saudi ArabiaA' },
      { text: 'Senegal', id: 'Senegal' },
      { text: 'Serbia', id: 'Serbia' },
      { text: 'Seychelles', id: 'Seychelles' },
      { text: 'Sierra Leone', id: 'Sierra Leone' },
      { text: 'Singapore', id: 'Singapore' },
      { text: 'Slovakia', id: 'Slovakia' },
      { text: 'Slovenia', id: 'Slovenia' },
      // {text: 'Solomon Islands', id: 'SB'}, 
      // {text: 'Somalia', id: 'SO'}, 
      { text: 'South Africa', id: 'South Africa' },
      { text: 'South Korea', id: 'South Korea' },
      // {text: 'South Georgia and the South Sandwich Islands', id: 'GS'}, 
      { text: 'Spain', id: 'Spain' },
      { text: 'Sri Lanka', id: 'Sri Lanka' },
      { text: 'St Kitts and Nevis', id: 'St Kitts and Nevis' },
      { text: 'St Lucia', id: 'St Lucia' },
      { text: 'St Vincent', id: 'St Vincent' },
      { text: 'Sudan', id: 'Sudan' },
      { text: 'Suriname', id: 'Suriname' },
      // {text: 'Svalbard and Jan Mayen', id: 'SJ'}, 
      { text: 'Swaziland', id: 'Swaziland' },
      { text: 'Sweden', id: 'Sweden' },
      { text: 'Switzerland', id: 'Switzerland' },
      { text: 'Syria', id: 'Syria' },
      { text: 'Taiwan', id: 'Taiwan' },
      { text: 'Tajikistan', id: 'Tajikistan' },
      { text: 'Tanzania', id: 'Tanzania' },
      { text: 'Thailand', id: 'Thailand' },
      { text: "Timor L'Este", id: "Timor L'Este" },
      { text: 'Togo', id: 'Togo' },
      //{text: 'Tokelau', id: 'TK'}, 
      { text: 'Tonga', id: 'Tonga' },
      { text: 'Trinidad and Tobago', id: 'Trinidad and Tobago' },
      { text: 'Tunisia', id: 'Tunisia' },
      { text: 'Turkey', id: 'Turkey' },
      { text: 'Turkmenistan', id: 'Turkmenistan' },
      { text: 'Turks and Caicos', id: 'Turks and Caicos' },
      //{text: 'Tuvalu', id: 'TV'}, 
      { text: 'Uganda', id: 'Uganda' },
      { text: 'Ukraine', id: 'Ukraine' },
      { text: 'United Arab Emirates', id: 'United Arab Emirates' },
      { text: 'United Kingdom', id: 'United Kingdom' },
      { text: 'United States', id: 'United States' },
      //{text: 'United States Minor Outlying Islands', id: 'UM'}, 
      { text: 'Uruguay', id: 'Uruguay' },
      { text: 'Uzbekistan', id: 'Uzbekistan' },
      // {text: 'Vanuatu', id: 'VU'}, 
      { text: 'Venezuela', id: 'Venezuela' },
      { text: 'Vietnam', id: 'Vietnam' },
      //{text: 'Virgin Islands, British', id: 'VG'}, 
      { text: 'Virgin Islands(US)', id: 'Virgin Islands(US)' },
      //{text: 'Wallis and Futuna', id: 'WF'}, 
      //{text: 'Western Sahara', id: 'EH'}, 
      { text: 'Yemen', id: 'Yemen' },
      { text: 'Zambia', id: 'Zambia' },
      { text: 'Zimbabwe', id: 'Zimbabwe' }
    ];
  }
  get registercontrol() {
    return this.registerForm.controls;
  }

  onSubmit() 
  {
    this.submitted = true;
    if (this.registerForm.valid) 
    {
      this.ValidareData.email = this.registerForm.value.email;
      this.ValidareData.mainUnit = this.registerForm.value.mainUnit;
      this.ValidareData.password = this.registerForm.value.password;
      this.ValidareData.country = this.registerForm.value.country;
      let Regencry = { inputData : CryptoJs.AES.encrypt(JSON.stringify(this.ValidareData),'qwerty').toString() };
      this.RegistrationService.postData(Regencry,ApiUrl.registration).subscribe(responseData => 
      {
          this.data = responseData;
          console.log(this.data);
          if (this.data.message == 'Success' || this.data.response_code == 200) 
          {            
            this.open();
            this.resetForm();
          }
          else if (this.data.type == 'EMAIL_ALREADY_EXISTS' || this.data.response_code == 400) {
            this.invalid_email = true;
            this.message = this.data.message;
            return this.message;
          }
      })
      return;
    }
    error => {
      this.errorMsg = error.error.errors;
    }
  }
  // Done by Rajat
  verifyEmail(value) 
  {
    this.emailExist = '';
    if(this.registerForm.controls.email.status == 'VALID') 
    {
      var data = {  email: value   }
      let Emailencry = { inputData : CryptoJs.AES.encrypt(JSON.stringify(data),'qwerty').toString() };
      this.RegistrationService.postData(Emailencry,ApiUrl.emailAlreadyExist).subscribe(response =>
      {
        if (response.response_code == 400) {
          this.emailExist = this.translate.instant("Email Address already in use");
        }
        else {
          this.emailExist = false
        }
      })
    }
  }
  viewPassword() {
  
    //console.log(this.isHide);
    let toggle = document.getElementsByTagName("input")[2];
    if (toggle.type == "password") {

      toggle.type = "text";
      this.isHide = false;

    }
    else {
      this.isHide = true;
      toggle.type = "password";
    }
  }
  viewConfirmPassword() {

    let togglec = document.getElementsByTagName("input")[3];
    if (togglec.type == "password") {
      togglec.type = "text";
      this.isHidec = false;
    }
    else {
      togglec.type = "password";
      this.isHidec = true;
    }
  }
  // Till here
  open() 
  {
    const modalRef = this.modalService.open(CustomPopUpComponent,this.modalOptions);
    modalRef.componentInstance.my_modal_title = this.translate.instant('Registration Successful');
    modalRef.componentInstance.title_noImg = 'true';
    this.modal_message = `<p>`+this.translate.instant('A registration email has been sent to'); 
    this.modal_message += ' '+this.registerForm.value.email+' '; 
    this.modal_message += this.translate.instant('with details regarding the activation of your account.')+`</p>`;
    this.modal_message += `<p>`+this.translate.instant('Once you receive this email, you can log on with the supplied temporary credentials.')+`</p>`;
    this.modal_message += `<p>`+this.translate.instant('If you do not receive this email within the hour, contact');
    this.modal_message += ` info@glucotrack.com `;
    this.modal_message += this.translate.instant('or');
    this.modal_message += ` 1-877-XXX-XXXX.</p>`;
    modalRef.componentInstance.my_modal_message = this.modal_message;
    modalRef.componentInstance.buttons = [{
      text: this.translate.instant('OK'),
      class: 'forget-butn-try',
      onClick: () => {
        modalRef.close();
      }
    }]
  }
  openTerms() 
  {
    const modalRef = this.modalService.open(CustomPopUpComponent, this.modalTermsOptions);
    modalRef.componentInstance.my_modal_title = this.translate.instant('Terms & Conditions');
    this.modal_message = this.policySection;  
    modalRef.componentInstance.my_modal_message = this.modal_message;
    modalRef.componentInstance.buttons = [/*{
      text: 'Cancel',
      class: 'forget-butn',
      onClick: () => {
        modalRef.close();
      }
    },*/ {
      text: 'OK',
      onClick: () => {
        modalRef.close();
      },
      class: 'forget-butn-try',
    }];
  }

}

