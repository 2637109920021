import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FullCalendarModule } from '@fullcalendar/angular';
import { GlucotrackRoutingModule } from './glucotrack-routing.module';
import { GlucotrackComponent } from './glucotrack.component';
import { ModalModule } from 'ngx-bootstrap/modal';
//import { ModalEmailComponent } from './dashboard.component';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { PipesModule } from '../core/pipe/pipe.module';
import { LayoutModule } from '../layout/layout.module'
import { SharedModule } from '../shared/shared.module';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [GlucotrackComponent],

  imports: [
    CommonModule,
    GlucotrackRoutingModule,
    ReactiveFormsModule,
    PipesModule,
    LayoutModule,
    SharedModule,
    FormsModule,
    ModalModule.forRoot()],
  entryComponents: [
  //  ModalEmailComponent
],
})
export class GlucotrackModule {
  constructor() {
  }
}
