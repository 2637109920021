import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nosidebar-layout',
  templateUrl: './nosidebarHeader-layout.component.html',
  styleUrls: ['./nosidebarHeader-layout.component.css']
})
export class NosidebarHeaderLayoutComponent implements OnInit {

  constructor() { 

    //console.log('no sidebar layout');
  }

  ngOnInit() {
  }

}
