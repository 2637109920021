import { Component, OnInit, TemplateRef } from '@angular/core';
import { ServicesService } from '../../core/Services/services.service';
import { ApiUrl } from '../../core/api.url';
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { BsModalService } from 'ngx-bootstrap/modal';
declare var $: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  response; footresponse; linkingtype; supportpageid;
  imageUrl;
  config = {
    backdrop: true,
    ignoreBackdropClick: true
  };
  sectionname;
  successmessage; errormessage; linkresponse; lresponse; delsection;
  modalRef;
  deleteForm: FormGroup;
  firstForm: FormGroup;
  secondForm: FormGroup;
  thirdForm: FormGroup;
  thirdOneForm: FormGroup;

  constructor(private FooterService: ServicesService, private httpClient: HttpClient, private router: Router,
    private formBuilder: FormBuilder, private modalService: BsModalService, public route: ActivatedRoute) {
    this.imageUrl = environment.baseUrl;    
  }  

  openPopup(sharetemplate: TemplateRef<any>, linktype) {
    this.modalRef = this.modalService.show(sharetemplate, this.config);
    this.linkingtype = linktype;
    this.successmessage = this.errormessage = '';
  }
  openEditPopup(sharetemplate: TemplateRef<any>, section, ids) {
    this.modalRef = this.modalService.show(sharetemplate, this.config);
    this.firstForm.reset();
    this.secondForm.reset();
    if (section == 'footerlink') {
      this.lresponse = this.linkresponse[ids];
    }
    this.successmessage = this.errormessage = '';
  }
  openDeletePopup(sharetemplate: TemplateRef<any>, ids, sectiondelete) {
    this.successmessage = this.errormessage = '';
    this.modalRef = this.modalService.show(sharetemplate, this.config);
    if (ids) {
      this.supportpageid = ids;
      this.delsection = sectiondelete;
    }
  }
  modalClose() {
    this.modalRef.hide();
  }

  ngOnInit() { }

  ngAfterViewInit() {  }  
}
