import { PipeTransform, Pipe } from "@angular/core";

@Pipe({
  name: "filterDevice",
  pure: false
})
export class FilterDevicePipe implements PipeTransform {
  transform(items: any[], isActive: boolean) {
    return items.filter(item => item.active == isActive);
  }
}
