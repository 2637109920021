import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-custom-delete-poi-modal',
  templateUrl: './custom-delete-poi-modal.component.html',
  styleUrls: ['./custom-delete-poi-modal.component.css']
})
export class CustomDeletePoiModalComponent implements OnInit {

  modalOptions: any;
  @Input() my_modal_title;
  @Input() title_noImg
  @Input() my_modal_message;
  @Input()
  buttons: Array<any> = [];

  constructor(public activeModal: NgbActiveModal) {

  }


  ngOnInit() {
    console.log(this.buttons);
  }

}
