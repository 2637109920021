import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
//import {NgbModal, ModalDismissReasons, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { ServicesService } from '../../core/Services/services.service';
import { ApiUrl } from '../../core/api.url';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CustomPopUpComponent } from '../../custom-pop-up/custom-pop-up.component';
import { TranslateService } from '@ngx-translate/core';
import { SharedApproveService } from 'src/app/shared.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit 
{  
  changepwdForm: FormGroup;
  menuToggle= false;
  userData: any;
  modalOptions: NgbModalOptions;
  modalTermsOptions: NgbModalOptions;
  modal_message: string = '';
  clickState = 0;
  navbarOpen: boolean;
  _routerlinkActive: boolean;
  loogedInUser: any;
  isImageSaved: boolean;
  profileimage: any;
  name: any;
  data: any;
  ishide = true;
  isDisabled: true;
  imageError: string;
  cardImageBase64: string;
  isImageexist: boolean;
  fileInput: any;
  setdata; response;
  errorMsg: '';
  successMsg;
  changePData = {
    newPassword: '',
    oldPassword: ''    
  }
  AllGeocast; Approve; Reject;

  loadAPI()
  { 
    this.CountService.getData(ApiUrl.count_geocast).subscribe(responseall =>
    {
      console.log(responseall);
      this.AllGeocast = responseall.result.AllGeocast[0].AllGeocast;
      this.Approve = responseall.result.Approve[0].Approve;
      this.Reject = responseall.result.Reject[0].Reject;
    });    
  }

  constructor(private router: Router,private actRoute: ActivatedRoute,private formBuilder: FormBuilder,
    private ImageService: ServicesService,private modalService: NgbModal,public translate: TranslateService,
    private CountService: ServicesService,private shareService: SharedApproveService) 
  { 
    // this.loadAPI();
    const loggedInData = localStorage.getItem('userData');    
    this.loogedInUser = loggedInData ? JSON.parse(loggedInData) : null;
    this.userData = this.loogedInUser;
    this.name = this.loogedInUser.firstName ? this.loogedInUser.firstName : 'Guest';
    if (this.loogedInUser.profileImage == '') {
      this.isImageSaved = false;
    } else {
      this.profileimage = "https://gpsstoryteller.mobileprogramming.net" + this.loogedInUser.profileImage;
      this.isImageexist = true;
      this.isImageSaved = true;
    }

    this.createForm();

    this.modalTermsOptions = {
      centered: true,
      scrollable: true,
      size: 'xl'
    };
    this.modalOptions = {
      centered: true,
    }
    this.createpwdForm();
  }
 
  createpwdForm() {
    this.changepwdForm = this.formBuilder.group({
      oldpassword: ['', [Validators.required]] , 
      newpassword: ['', [Validators.required]]   
    })
  }
  switchLang(lang: string) {
    this.translate.use(lang);
  }
  ngOnInit() {  
    this.loadAPI();
    this.shareService.currentApicall.subscribe(api =>{
      if(api){
        this.loadAPI();
      }
    })
  }
  menuT(){
    this.menuToggle = !this.menuToggle 
  }
  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }
  imageForm: FormGroup;
  submitted = false;
  ValidareData = {
    profilepic: ''
  }
  createForm() {
    this.imageForm = this.formBuilder.group({
      profilepic: ['', []]

    })
  }
  get imagecontrol() {
    return this.imageForm.controls;
  }
  get pwdControl(){
    return this.changepwdForm.controls;
  }

  fileChangeEvent(fileInput: any) 
  {    
    this.imageError = null;
    if (fileInput.target.files && fileInput.target.files[0]) {
      // Size Filter Bytes
      const max_size = 20971520;
      const allowed_types = ['image/png', 'image/jpeg'];
      const max_height = 15200;
      const max_width = 25600;
      if (fileInput.target.files[0].size > max_size) {
        this.imageError =
          'Maximum size allowed is ' + max_size / 1000 + 'Mb';
        return false;
      }

      // if (!includes(allowed_types, fileInput.target.files[0].type)) {
      //     this.imageError = 'Only Images are allowed ( JPG | PNG )';
      //     return false;
      // }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];

          if (img_height > max_height && img_width > max_width) {
            this.imageError =
              'Maximum dimentions allowed ' +
              max_height +
              '*' +
              max_width +
              'px';
            return false;
          } else {
            const imgBase64Path = e.target.result;
            //console.log("imges base"+imgBase64Path);
            this.cardImageBase64 = imgBase64Path;
            this.isImageSaved = true;
            this.isImageexist = false;
            if (this.cardImageBase64) 
            {              
              this.ImageService.putData({ profilePic: this.cardImageBase64 }, ApiUrl.imageEdit).subscribe(responseData => 
              {                
                this.data = responseData
                if (this.data.message == 'Success' || this.data.response_code == 200) 
                {
                  localStorage.clear();
                  localStorage.setItem('userData', JSON.stringify(responseData));                
                  window.location.reload();
                }
              })             
            }
          }
        };
      };
      reader.readAsDataURL(fileInput.target.files[0]); 
    }
  }
  closeNav() {
    this.clickState = 0;
    document.getElementById("mySidenav").style.width = "0";
    document.getElementById("main").style.marginLeft = "0";
    document.getElementById("inside-header").style.width = "100%";
  }
  logout() 
  {
    const modalRef = this.modalService.open(CustomPopUpComponent, this.modalOptions);
    modalRef.componentInstance.my_modal_title = this.translate.instant('Logout');
    this.modal_message = `<p class="text-center">`+this.translate.instant('Do you really want to logout?')+`</p>`;
    modalRef.componentInstance.my_modal_message = this.modal_message;
    modalRef.componentInstance.buttons = [{
      text: this.translate.instant('No'),
      class: 'forget-butn mr-4',
      onClick: () => {
        modalRef.close();
      }
    },
    {
      text: this.translate.instant('Yes'),
      class: 'forget-butn-try',
      onClick: () => {
       // localStorage.clear();
        localStorage.removeItem('userData')
        this.router.navigate(['/home']);
        modalRef.close();
      }
    }
    ]
  }
  onSubmit()
  {
    this.errorMsg = '';
    this.submitted = true;
    if(this.changepwdForm.valid) 
    {
      this.changePData.newPassword = this.changepwdForm.value.newpassword;  
      this.changePData.oldPassword = this.changepwdForm.value.oldpassword;
      this.ImageService.postData(this.changePData,ApiUrl.changePassword).subscribe(responseData => 
      {
        let titles = 'Change Password';
        this.data = responseData;
        if (this.data.message == 'Success' || this.data.response_code == 200)
        {            
          this.openSucess(titles,'Password change successfully!');
          this.changepwdForm.reset();
          this.submitted = false;
        }
        else
        {
          let errors  = this.data.message;
          this.openfailure(titles,errors);
          this.changepwdForm.reset();
          this.submitted = false;
        }
      })
      //  return;
    }
    error => {
      this.errorMsg = error.error.errors;
    }
  }  
  openSucess(titles,messages) 
  {
    const modalRef = this.modalService.open(CustomPopUpComponent, this.modalOptions);
    modalRef.componentInstance.my_modal_title = titles;
    this.modal_message = this.translate.instant(`<h5 class="text-center mb-3">`+messages+`</h5>`);
   // this.modal_message = this.translate.instant('Success');
    modalRef.componentInstance.my_modal_message = this.modal_message;
    modalRef.componentInstance.buttons = [{
      text: this.translate.instant('OK'),
      class: 'forget-butn-try',
      onClick: () => {
        modalRef.close();       
      }      
    }]
  }
  openfailure(titles,messages) 
  { 
    const modalRef = this.modalService.open(CustomPopUpComponent, this.modalOptions);
    modalRef.componentInstance.my_modal_title = titles;
    this.modal_message = this.translate.instant(`<h5 class="text-center mb-3">` + messages + `</h5>`);
    modalRef.componentInstance.my_modal_message = this.modal_message;
    modalRef.componentInstance.buttons = [{
      text: this.translate.instant('Cancel'),
      class: 'forget-butn',
      onClick: () => {
        modalRef.close();
      }
    },
    {
      text: this.translate.instant('Try Again'),
      onClick: () => {
        modalRef.close();
      },
      class: 'forget-butn-try btn-wd',
    }]; 
  }
}



