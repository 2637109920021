import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn : 'root'
})
export class SharedApproveService {

  private isApicall = new BehaviorSubject(false);
  currentApicall = this.isApicall.asObservable();

  constructor() { }

  changeApicall(apiCall:boolean) {
    this.isApicall.next(apiCall);
  }

}