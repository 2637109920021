import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.css']
})
export class MainLayoutComponent implements OnInit {
  clickState = 0;
  // navbarOpen = false;
  btn = document.querySelector('.button-elem');
  constructor() {
    console.log(' in layout');
  }

  ngOnInit() {  }


  /*
  openNav() {
    var gl_Width = window.innerWidth;
    if (gl_Width < 769) {
      document.getElementById("mySidenav").style.width = "250px";
      document.getElementById("main").style.marginLeft = "0";

    } else {
      document.getElementById("mySidenav").style.width = "250px";
      document.getElementById("main").style.marginLeft = "250px";
      document.getElementById("inside-header").style.width = "calc(100% - 250px)";
    }

  }
  closeNav() {
    this.clickState = 0;
    document.getElementById("mySidenav").style.width = "0";
    document.getElementById("main").style.marginLeft = "0";
    document.getElementById("inside-header").style.width = "100%";
  }


  cutom() {

    if (this.clickState == 0) {
      // code snippet 1
      this.openNav();
      this.clickState = 1;
    } else {
      // code snippet 2
      this.closeNav();
      this.clickState = 0;
    }
  }


*/

}
