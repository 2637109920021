import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpErrorResponse,
  HttpResponse
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { NgxUiLoaderService } from "ngx-ui-loader";

import { ApiUrl } from "src/app/core/apiUrl";
import { catchError, tap } from "rxjs/operators";
//import { ToastrService } from "ngx-toastr";

@Injectable()
export class Interceptor implements HttpInterceptor {
  userData : any;
  token: string = '';
  constructor() 
  {
    let localData:any = localStorage.getItem('userData');
    if(localData) 
    {
      this.userData = JSON.parse(localStorage.getItem('userData'));
      if(this.userData) this.token = this.userData.result.token;
    }      
  }

  intercept(req: HttpRequest<any>,next: HttpHandler): Observable<HttpEvent<any>> 
  {
    var newHeaders = req.headers;
    var contentType = newHeaders.get('Content-Type');
    newHeaders= newHeaders.append('Access-Control-Allow-Methods', 'GET,PUT,POST,DELETE,PATCH,OPTIONS');    
    newHeaders= newHeaders.append("Access-Control-Allow-Origin", "*");
    // if (!(contentType) || contentType=='application/form-data') {
    newHeaders.set('Content-Type', 'application/json');
    // }
    // {headers: {'Content-Type': 'application/json'}}
    if (this.token) {
        // If we have a token, we append it to our new headers
      newHeaders = newHeaders.append('Authorization', this.token);
    }    
    // Finally we have to clone our request with our new headers
    // This is required because HttpRequests are immutable
    const authReq = req.clone({
      setHeaders: {
        Authorization: this.token
      }
    });
    return next.handle(authReq).pipe(
      tap(evt => {
        if (evt instanceof HttpResponse) 
        {        
        //  this.ngxService.stop();
        }
      }),
      catchError(err => {
        // onError
        if (err instanceof HttpErrorResponse) {
          //this.ngxService.stop();
          // try {
          //   this.toasterService.error(err.error.message, err.error.title);
          // } catch (e) {
          //   this.toasterService.error("An error occurred", "");
          // }

          if (err.status === 401) {
            window.location.href = "/login";
          }
        }
        return throwError(err);
      })
    ) as any;
  }
}
