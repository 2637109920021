import { Component,TemplateRef,OnInit,Input } from '@angular/core';
import { FormBuilder,FormGroup } from "@angular/forms";
import { Router,ActivatedRoute } from "@angular/router";
import { ServicesService } from '../core/Services/services.service';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { ApiUrl } from '../core/api.url';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-glucotrack',
  templateUrl: './glucotrack.component.html',
  styleUrls: ['./glucotrack.component.css']
})

export class GlucotrackComponent implements OnInit 
{
  IsActive:boolean = false;
  IssecA:boolean = false;
  IssecB:boolean = false;
  IssecC:boolean = false;
  IssecD:boolean = false;
  IssecE:boolean = false;
  IssecF:boolean = false;
  IssecG:boolean = false;
  IssecH:boolean = false;
  IssecI:boolean = false;
  IssecJ:boolean = false;
  IssecY:boolean = false;
  IssecZ:boolean = false;
  IssecIA:boolean = false;

  response; testresponse; newresponse; deleteForm; testimonial; iconresponse; response17; response18;
  response1; response2; response3; response4; response5; response6; response7; response16; response19;
  response8; response9; response10; response11; response12; response13; response14; response15; response20; response21;
  successmessage; errormessage;
  bannerImage; hideBanner;
  modalRef;
  imageUrl; sectionname;
  config = {
    backdrop: true,
    ignoreBackdropClick: true
  };

  oneZeroForm: FormGroup;
  ShowHideForm: FormGroup;
  bannerForm: FormGroup;
  firstForm: FormGroup;
  secondForm: FormGroup;
  thirdForm: FormGroup;
  fourthForm: FormGroup;
  fifthForm: FormGroup;
  sixthForm: FormGroup;
  seventhForm: FormGroup;
  twelfthForm: FormGroup;
  twelfthEditForm: FormGroup;
  fourteenthForm: FormGroup;
  fifteenthForm: FormGroup;
  sixteenthForm: FormGroup;

  constructor(private GlucotrackService:ServicesService,private modalService: BsModalService,private httpClient:HttpClient,
    private formBuilder: FormBuilder,private router: Router,public route:ActivatedRoute) 
  { 
    this.imageUrl = environment.baseUrl;
    this.loadAPI();
    this.ShowHideForm = this.formBuilder.group({
      showhide:[''],  landingId:['']
    });
    this.bannerForm = this.formBuilder.group({
      bannerimage:[''],  heading1:['']
    });   
    this.oneZeroForm = this.formBuilder.group({
      logo:['']
    });   
    this.firstForm = this.formBuilder.group({
      icon:[''], title:[''],  description:['']
    });
    this.secondForm = this.formBuilder.group({
      icon:[''], title:[''],  description:[''], position:['']
    }); 
    this.thirdForm = this.formBuilder.group({
      icon:[''], title:[''],  description:[''], position:['']
    });
    this.fourthForm = this.formBuilder.group({
      icon:[''], title:[''],  description:['']
    });
    this.fifthForm = this.formBuilder.group({
      icon:[''], title:[''],  description:['']
    }); 
    this.sixthForm = this.formBuilder.group({
      icon:[''], title:[''],  description:['']
    });
    this.seventhForm = this.formBuilder.group({
      icon:[''], title:['']
    });
    this.twelfthForm = this.formBuilder.group({
      message:[''], name:[''],  title:['']
    });
    this.twelfthEditForm = this.formBuilder.group({
      message:[''], name:[''],  title:['']
    }); 
    this.deleteForm = this.formBuilder.group({
      landingsliderid:['']
    });
    this.fourteenthForm = this.formBuilder.group({
      question1:[''], point8:[''], point9:[''], point10:[''], point11:[''], point12:[''], point13:[''], point14:['']
    });
    this.fifteenthForm = this.formBuilder.group({
      question:[''], point1:[''], point2:[''], point3:[''], point4:[''], point5:[''], point6:[''], point7:['']
    });
    this.sixteenthForm = this.formBuilder.group({
      icon:['']
    });
  }
  loadAPI()
  {
    this.GlucotrackService.getData(ApiUrl.getGlucotrackPage).subscribe(responseall =>
    {
      this.response = responseall.data[0];
      this.response1 = responseall.data[0].glucotrackIconSection[0];
      this.response2 = responseall.data[0].glucotrackIconSection[1];
      this.response3 = responseall.data[0].glucotrackIconSection[2];
      this.response4 = responseall.data[0].glucotrackIconSection[3];
      this.response5 = responseall.data[0].glucotrackIconSection[4];
      this.response6 = responseall.data[0].glucotrackIconSection[5];
      this.response7 = responseall.data[0].glucotrackIconSection[6];
      this.response8 = responseall.data[0].glucotrackIconSection[7];
      this.response9 = responseall.data[0].glucotrackIconSection[8];
      this.response10 = responseall.data[0].glucotrackIconSection[9];
      this.response11 = responseall.data[0].glucotrackIconSection[10];
      this.response12 = responseall.data[0].glucotrackIconSection[11];
      this.response13 = responseall.data[0].glucotrackIconSection[12];
      this.response14 = responseall.data[0].glucotrackIconSection[13];
      this.response15 = responseall.data[0].glucotrackIconSection[14];
      this.response16 = responseall.data[0].glucotrackIconSection[15];
      this.response17 = responseall.data[0].glucotrackIconSection[16];
      this.response18 = responseall.data[0].glucotrackIconSection[17];
      this.response19 = responseall.data[0].glucotrackIconSection[18];
      this.response20 = responseall.data[0].glucotrackIconSection[19];
      this.response21 = responseall.data[0].glucotrackIconSection[20];
      this.testimonial = responseall;
      this.bannerImage = environment.baseUrl+this.response.bannerimage;
    });    
  }
  SHForm = {
    hideGlucoBanner: '', hideGlucoSec1: '', hideGlucoSec2: '',  hideGlucoSec3: '', hideGlucoSec4: '',
    hideGlucoSec5: '',  hideGlucoSec6: '', hideGlucoSec7: '',  hideGlucoSec8: '', hideGlucoSec9: '',  
    hideGlucoSec10: '', hideGlucoSec11: '', landingId : ''
  }
  testForm = {
    message:'', name:'',  title:'' 
  }
  testEditForm = {
    message:'', name:'',  title:'',  landingSliderId:''
  }
  DelForm = {
    landingSliderId : ''
  }
  FourteenForm = {
    question1:'', point8:'', point9:'', point10:'', point11:'', point12:'', point13:'', point14:''
  }
  FifteenForm = {
    question:'', point1:'', point2:'', point3:'', point4:'', point5:'', point6:'', point7:''
  }
  modalClose() 
  {
    this.modalRef.hide();
  }
  uploadFile(event) 
  {
    if(event.target.files.length > 0) 
    {
      const file = event.target.files[0]; 
      this.bannerForm.patchValue({ bannerimage : file });
      this.oneZeroForm.patchValue({ logo : file });
      this.firstForm.patchValue({ icon : file });
      this.secondForm.patchValue({ icon : file });
      this.thirdForm.patchValue({ icon : file });
      this.fourthForm.patchValue({ icon : file });
      this.fifthForm.patchValue({ icon : file });
      this.sixthForm.patchValue({ icon : file });
      this.seventhForm.patchValue({ icon : file }); 
      this.sixteenthForm.patchValue({ icon : file });
    }
  }
  onShowHideSubmit(section)
  {       
    if(this.ShowHideForm.get('showhide').value > 0 && section!='')     
    {
      if(section=='Banner')
      {
        this.SHForm.hideGlucoBanner = this.ShowHideForm.get('showhide').value;
      }
      else if(section=='First')
      {
        this.SHForm.hideGlucoSec1 = this.ShowHideForm.get('showhide').value;
      }
      else if(section=='Second')
      {
        this.SHForm.hideGlucoSec2 = this.ShowHideForm.get('showhide').value;
      }
      else if(section=='Third')
      {
        this.SHForm.hideGlucoSec3 = this.ShowHideForm.get('showhide').value;
      }
      else if(section=='Fourth')
      {
        this.SHForm.hideGlucoSec4 = this.ShowHideForm.get('showhide').value;
      }
      else if(section=='Fifth')
      {
        this.SHForm.hideGlucoSec5 = this.ShowHideForm.get('showhide').value;
      }
      else if(section=='Sixth')
      {
        this.SHForm.hideGlucoSec6 = this.ShowHideForm.get('showhide').value;
      }
      else if(section=='Seventh')
      {
        this.SHForm.hideGlucoSec7 = this.ShowHideForm.get('showhide').value;
      }
      else if(section=='Eighth')
      {
        this.SHForm.hideGlucoSec8 = this.ShowHideForm.get('showhide').value;
      }
      else if(section=='Ninth')
      {
        this.SHForm.hideGlucoSec9 = this.ShowHideForm.get('showhide').value;
      }
      else if(section=='Tenth')
      {
        this.SHForm.hideGlucoSec10 = this.ShowHideForm.get('showhide').value;
      }
      else if(section=='Eleventh')
      {
        this.SHForm.hideGlucoSec11 = this.ShowHideForm.get('showhide').value;
      }
      this.SHForm.landingId = this.response._id;
    }    
    this.GlucotrackService.postData(this.SHForm,ApiUrl.hideShowGlucoBanner).subscribe(lbresponse =>
    {      
      this.response = lbresponse;
      if(this.response.response_code == 200)
      {
        this.successmessage = "Updated Successfully";
        this.loadAPI();
        this.modalClose();
      }
    });
  }
  onOneZeroSubmit()
  {
    if(this.oneZeroForm.get('logo').value)
    {
      const formData = new FormData(); 
      if(this.oneZeroForm.get('logo').value)
        formData.append('logo', this.oneZeroForm.get('logo').value);

      this.httpClient.post(environment.apiBaseUrl+ApiUrl.addGlucoLogo,formData).subscribe(responseall =>
      {
        this.response = responseall;
        if(this.response.response_code == 200)
        {
          this.successmessage = "Updated Successfully";
          this.loadAPI();
          this.modalClose();
        }
      });
    }
  }
  onBannerSubmit() 
  {
    const formData = new FormData(); 
    if(this.bannerForm.get('heading1').value!='')     
      formData.append('heading1', this.bannerForm.get('heading1').value);
    else formData.append('heading1',' ');
    
    if(this.bannerForm.get('bannerimage').value)
      formData.append('bannerimage', this.bannerForm.get('bannerimage').value);

    this.httpClient.post(environment.apiBaseUrl+ApiUrl.addGlucotrackBanner,formData).subscribe(responseall =>
    {
      this.response = responseall;
      if(this.response.response_code == 200)
      {
        this.successmessage = "Updated Successfully";
        this.loadAPI();
        this.modalClose();
      }
    });
  }
  onFirstSubmit() 
  {    
    const formData = new FormData(); 
    if(this.firstForm.get('icon').value)
      formData.append('icon', this.firstForm.get('icon').value);

    if(this.firstForm.get('title').value)     
      formData.append('title', this.firstForm.get('title').value);
  
    if(this.firstForm.get('description').value)
      formData.append('description', this.firstForm.get('description').value);

    formData.append('position','0');

    this.httpClient.post(environment.apiBaseUrl+ApiUrl.addGlucotrackIcon,formData).subscribe(responseall =>
    {
      this.response = responseall;
      if(this.response.response_code == 200)
      {
        this.successmessage = "Created Successfully";
        this.loadAPI();
        this.modalClose();
      }
    });    
  }
  onSecondSubmit() 
  {
    if(this.secondForm.get('icon').value && this.secondForm.get('title').value 
    && this.secondForm.get('position').value && this.secondForm.get('description').value)
    {
      const formData = new FormData(); 
      if(this.secondForm.get('icon').value)
        formData.append('icon', this.secondForm.get('icon').value);

      if(this.secondForm.get('title').value)     
        formData.append('title', this.secondForm.get('title').value);
    
      if(this.secondForm.get('description').value)
        formData.append('description', this.secondForm.get('description').value);

        if(this.secondForm.get('position').value)
        formData.append('position', this.secondForm.get('position').value);

      this.httpClient.post(environment.apiBaseUrl+ApiUrl.addGlucotrackIcon,formData).subscribe(responseall =>
      {
        this.response = responseall;
        if(this.response.response_code == 200)
        {
          this.successmessage = "Created Successfully";
          this.loadAPI();
          this.modalClose();
        }
      });
    }
  }
  onThirdSubmit() 
  {
    if(this.thirdForm.get('icon').value && this.thirdForm.get('title').value 
    && this.thirdForm.get('position').value && this.thirdForm.get('description').value)
    {
      const formData = new FormData(); 
      if(this.thirdForm.get('icon').value)
        formData.append('icon', this.thirdForm.get('icon').value);

      if(this.thirdForm.get('title').value)     
        formData.append('title', this.thirdForm.get('title').value);
    
      if(this.thirdForm.get('description').value)
        formData.append('description', this.thirdForm.get('description').value);

        if(this.thirdForm.get('position').value)
        formData.append('position', this.thirdForm.get('position').value);

      this.httpClient.post(environment.apiBaseUrl+ApiUrl.addGlucotrackIcon,formData).subscribe(responseall =>
      {
        this.response = responseall;
        if(this.response.response_code == 200)
        {
          this.successmessage = "Created Successfully";
          this.loadAPI();
          this.modalClose();
        }
      });
    }
  }
  onFourthSubmit() 
  {
    if(this.fourthForm.get('icon').value && this.fourthForm.get('title').value 
    && this.fourthForm.get('description').value)
    {
      const formData = new FormData(); 
      if(this.fourthForm.get('icon').value)
        formData.append('icon', this.fourthForm.get('icon').value);

      if(this.fourthForm.get('title').value)     
        formData.append('title', this.fourthForm.get('title').value);
    
      if(this.fourthForm.get('description').value)
        formData.append('description', this.fourthForm.get('description').value);

      formData.append('position','9');

      this.httpClient.post(environment.apiBaseUrl+ApiUrl.addGlucotrackIcon,formData).subscribe(responseall =>
      {
        this.response = responseall;
        if(this.response.response_code == 200)
        {
          this.successmessage = "Created Successfully";
          this.loadAPI();
          this.modalClose();
        }
      });
    }
  }
  onFifthSubmit() 
  {
    if(this.fifthForm.get('icon').value && this.fifthForm.get('title').value)
    {
      const formData = new FormData(); 
      if(this.fifthForm.get('icon').value)
        formData.append('icon', this.fifthForm.get('icon').value);

      if(this.fifthForm.get('title').value)     
        formData.append('title', this.fifthForm.get('title').value);

      formData.append('position','10');

      this.httpClient.post(environment.apiBaseUrl+ApiUrl.addGlucotrackIcon,formData).subscribe(responseall =>
      {
        this.response = responseall;
        if(this.response.response_code == 200)
        {
          this.successmessage = "Created Successfully";
          this.loadAPI();
          this.modalClose();
        }
      });
    }
  }
  onSixthSubmit() 
  {    
    const formData = new FormData(); 
    if(this.sixthForm.get('icon').value)
      formData.append('icon', this.sixthForm.get('icon').value);

    if(this.sixthForm.get('title').value)     
      formData.append('title', this.sixthForm.get('title').value);

    if(this.sixthForm.get('description').value)     
      formData.append('description', this.sixthForm.get('description').value);

    formData.append('position','11');

    this.httpClient.post(environment.apiBaseUrl+ApiUrl.addGlucotrackIcon,formData).subscribe(responseall =>
    {
      this.response = responseall;
      if(this.response.response_code == 200)
      {
        this.successmessage = "Created Successfully";
        this.loadAPI();
        this.modalClose();
      }
    });    
  }
  onSeventhSubmit() 
  {
    const formData = new FormData(); 
    if(this.seventhForm.get('icon').value)
      formData.append('icon', this.seventhForm.get('icon').value);

    if(this.seventhForm.get('title').value)     
      formData.append('title', this.seventhForm.get('title').value);

    formData.append('position','12');

    this.httpClient.post(environment.apiBaseUrl+ApiUrl.addGlucotrackIcon,formData).subscribe(responseall =>
    {
      this.response = responseall;
      if(this.response.response_code == 200)
      {
        this.successmessage = "Created Successfully";
        this.loadAPI();
        this.modalClose();
      }
    });    
  }  
  openDeletePopup(sharetemplate: TemplateRef<any>,ids)
  {
    this.successmessage = this.errormessage = '';
    this.modalRef = this.modalService.show(sharetemplate,this.config);
    if(ids)
    {
      this.newresponse = ids;
    }
  }  
  openEditPopup(sharetemplate: TemplateRef<any>,ids) 
  {
    this.modalRef = this.modalService.show(sharetemplate,this.config);
    this.newresponse = this.testimonial.sliderData[ids];
    this.iconresponse = ids;
    this.successmessage = this.errormessage = '';
  }
  
  onTwelfthEditSubmit(lsid) 
  {
    if(this.twelfthEditForm.get('message').value)
      this.testEditForm.message = this.twelfthEditForm.get('message').value;

    if(this.twelfthEditForm.get('name').value)
      this.testEditForm.name = this.twelfthEditForm.get('name').value;

    if(this.twelfthEditForm.get('title').value)     
      this.testEditForm.title = this.twelfthEditForm.get('title').value;

    if(lsid)
      this.testEditForm.landingSliderId = lsid;
      
    console.log(this.twelfthEditForm);

    this.httpClient.post(environment.apiBaseUrl+ApiUrl.updateGlucoSlider,this.testEditForm).subscribe(responseall =>
    {
      this.response = responseall;
      if(this.response.response_code == 200)
      {
        this.successmessage = "Updated Successfully";
        this.loadAPI();
        this.modalClose();
      }
      else{
        this.errormessage = "Please fill all fields";
      }
    });
  }   
  onTwelfthSubmit() 
  {
    if(this.twelfthForm.get('message').value)
      this.testForm.message = this.twelfthForm.get('message').value;

    if(this.twelfthForm.get('name').value)
      this.testForm.name = this.twelfthForm.get('name').value;

    if(this.twelfthForm.get('title').value)     
      this.testForm.title = this.twelfthForm.get('title').value;

    this.httpClient.post(environment.apiBaseUrl+ApiUrl.addGlucotrackSlider,this.testForm).subscribe(responseall =>
    {      
      this.response = responseall;
      if(this.response.response_code == 200)
      {
        this.successmessage = "Created Successfully";
        this.loadAPI();
        this.modalClose();
      }
      else{
        this.errormessage = "Please fill all fields";
      }
    });
  }
  onFourteenthSubmit() 
  {
    if(this.fourteenthForm.get('question1').value)
      this.FourteenForm.question1 = this.fourteenthForm.get('question1').value;

    if(this.fourteenthForm.get('point8').value)
      this.FourteenForm.point8 = this.fourteenthForm.get('point8').value;

    if(this.fourteenthForm.get('point9').value)     
      this.FourteenForm.point9 = this.fourteenthForm.get('point9').value;

    if(this.fourteenthForm.get('point10').value)
      this.FourteenForm.point10 = this.fourteenthForm.get('point10').value;

    if(this.fourteenthForm.get('point11').value)     
      this.FourteenForm.point11 = this.fourteenthForm.get('point11').value;

    if(this.fourteenthForm.get('point12').value)
      this.FourteenForm.point12 = this.fourteenthForm.get('point12').value;

    if(this.fourteenthForm.get('point13').value)     
      this.FourteenForm.point13 = this.fourteenthForm.get('point13').value;

    if(this.fourteenthForm.get('point14').value)
      this.FourteenForm.point14 = this.fourteenthForm.get('point14').value;

    this.httpClient.post(environment.apiBaseUrl+ApiUrl.addGlucotrackObjective,this.FourteenForm).subscribe(responseall =>
    {      
      this.response = responseall;
      if(this.response.response_code == 200)
      {
        this.successmessage = "Created Successfully";
        this.loadAPI();
        this.modalClose();
      }
      else{
        this.errormessage = "Please fill all fields";
      }
    });
  }
  onFifteenthSubmit() 
  {
    if(this.fifteenthForm.get('question').value)
      this.FifteenForm.question = this.fifteenthForm.get('question').value;

    if(this.fifteenthForm.get('point1').value)
      this.FifteenForm.point1 = this.fifteenthForm.get('point1').value;

    if(this.fifteenthForm.get('point2').value)     
      this.FifteenForm.point2 = this.fifteenthForm.get('point2').value;

    if(this.fifteenthForm.get('point3').value)
      this.FifteenForm.point3 = this.fifteenthForm.get('point3').value;

    if(this.fifteenthForm.get('point4').value)     
      this.FifteenForm.point4 = this.fifteenthForm.get('point4').value;

    if(this.fifteenthForm.get('point5').value)
      this.FifteenForm.point5 = this.fifteenthForm.get('point5').value;

    if(this.fifteenthForm.get('point6').value)     
      this.FifteenForm.point6 = this.fifteenthForm.get('point6').value;

    if(this.fifteenthForm.get('point7').value)
      this.FifteenForm.point7 = this.fifteenthForm.get('point7').value;

    this.httpClient.post(environment.apiBaseUrl+ApiUrl.addGlucotrackFeatures,this.FifteenForm).subscribe(responseall =>
    {      
      this.response = responseall;
      if(this.response.response_code == 200)
      {
        this.successmessage = "Created Successfully";
        this.loadAPI();
        this.modalClose();
      }
      else{
        this.errormessage = "Please fill all fields";
      }
    });
  }
  onSixteenthSubmit(positions) 
  {
    const formData = new FormData(); 
    if(this.sixteenthForm.get('icon').value)
      formData.append('icon', this.sixteenthForm.get('icon').value);

    formData.append('position',positions);

    this.httpClient.post(environment.apiBaseUrl+ApiUrl.addGlucotrackIcon,formData).subscribe(responseall =>
    {
      this.response = responseall;
      if(this.response.response_code == 200)
      {
        this.successmessage = "Updated Successfully";
        this.loadAPI();
        this.modalClose();
      }
    });
  }
  onDeleteFormSubmit(slideid)
  {  
    if(slideid>0)     
    {
      this.DelForm.landingSliderId = slideid;
    }    
    this.GlucotrackService.postData(this.DelForm,ApiUrl.deleteGlucoSlider).subscribe(delresponse =>
    {  
      if(delresponse.response_code == 200)
      {
        this.successmessage = "Updated Successfully";
        this.loadAPI();
        this.modalClose();
      }
    });
  }
  openPopup(sharetemplate: TemplateRef<any>) 
  {
    this.modalRef = this.modalService.show(sharetemplate,this.config);
    this.successmessage = this.errormessage = '';
  }
  openShowHidePopup(sharetemplate: TemplateRef<any>,section) 
  {
    this.modalRef = this.modalService.show(sharetemplate,this.config);
    this.sectionname = section;
    this.successmessage = this.errormessage = '';
  }
  sec_glucoConnect()
  {
    if(this.IssecH){
      return '2px solid #7474C1';
    }
      else {
        return 'none'
      }
  }
  sec_testimonial()
  {
    if(this.IssecI){
      return '2px solid #7474C1';
    }
      else {
        return 'none'
      }
  }
  gluco_banner()
  {
    if(this.IsActive){
      return '2px solid #7474C1';
    }
      else {
        return 'none'
      }
  }
  gluco_compliance()
  {
    if(this.IssecA){
      return '2px solid #7474c1'
    }
    else{
      return 'none'
    }
  }
  gluco_non_invasive()
  {
    if(this.IssecB){
      return '2px solid #7474c1'
    }else{
      return 'none'
    }
  }
  gluco_enhance()
  {
    if(this.IssecC){
      return '2px solid #7474c1'
    }
    else{
      return 'none'
    }
  }
  gluco_Innovative()
  {
    if(this.IssecD){
      return '2px solid #7474c1'
    }
    else{
      return 'none'
    }
  }
  gluco_uniqueValue()
  {
    if(this.IssecJ){
      return '2px solid #7474c1'
    } else{
      return 'none'
    }
  }
  gluco_glucosetissue()
  {
    if(this.IssecE){
      return '2px solid #7474c1'
    } else{
      return 'none'
    }
  }
  gluco_howworks()
  {
    if(this.IssecF){
      return '2px solid #7474c1'
    } else{
      return 'none'
    }
  }
  gluco_comprehensive()
  {
    if(this.IssecG){
      return '2px solid #7474c1'
    } else{
      return 'none'
    }
  }

  gluco_comprehensive2()
  {
    if(this.IssecZ){
      return '2px solid #7474c1'
    } else{
      return 'none'
    }
  }

  gluco_connect1()
  {
    if(this.IssecY){
      return '2px solid #7474c1'
    } else{
      return 'none'
    }
  }
  gluco_connectIA(){
    if(this.IssecIA){
      return '2px solid #7474c1'
    } else{
      return 'none'
    }
  }
  ngOnInit()  {  }
}