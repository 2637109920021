export const ApiUrl = {

    login: 'admin/login',
    get_all_poi: 'admin/get-all-poi',
    set_approve_reject_poi: 'admin/set-approve-reject-poi',
    get_poi_details: 'admin/get-poi-details',
    count_geocast: 'admin/count-geocast',
    reset_password: 'admin/reset_password',
    update_password: 'admin/update_password',
    forget_password: 'admin/forget_password',
    deletePoiAdmin: 'admin/move-poi',

    /*Landing Page */
    getLandingPage: 'websiteAdmin/getLandingPage',
    addLandingBanner: 'websiteAdmin/addLandingBanner',
    addLandingIcon: 'websiteAdmin/addLandingIcon',
    addLandingIconSec: 'websiteAdmin/addLandingIconSec',
    addLandingSliderAuthor: 'websiteAdmin/addLandingSliderAuthor',
    addLandingSlider: 'websiteAdmin/addLandingSlider',
    updateLandingSliderAuthor: 'websiteAdmin/updateLandingSliderAuthor',
    updateLandingSlider: 'websiteAdmin/updateLandingSlider',
    hideShowLandingBanner: 'websiteAdmin/hideShowLandingBanner',
    hideShowLandingIcon: 'websiteAdmin/hideShowLandingIcon',
    deleteLandingSlider: 'websiteAdmin/deleteLandingSlider',
    addLandingText: 'websiteAdmin/addLandingText',
    addLandingLogo: 'websiteAdmin/addLandingLogo',
    addImageIcon: 'websiteAdmin/addImageIcon',

    /*GlucoTrack Page */
    getGlucotrackPage: 'websiteAdmin/getGlucotrackPage',
    addGlucotrackBanner: 'websiteAdmin/addGlucotrackBanner',
    hideShowGlucoBanner: 'websiteAdmin/hideShowGlucoBanner',
    addGlucotrackIcon: 'websiteAdmin/addGlucotrackIcon',
    addGlucotrackSlider: 'websiteAdmin/addGlucotrackSlider',
    deleteGlucoSlider: 'websiteAdmin/deleteGlucoSlider',
    updateGlucoSlider: 'websiteAdmin/updateGlucoSlider',
    addGlucoLogo: 'websiteAdmin/addGlucoLogo',
    addGlucotrackObjective: 'websiteAdmin/addGlucotrackObjective',
    addGlucotrackFeatures: 'websiteAdmin/addGlucotrackFeatures',

    /* Support Page */
    getSupportPage: 'websiteAdmin/getSupportPage',
    addSupportBanner: 'websiteAdmin/addSupportBanner',
    hideShowSupportBanner: 'websiteAdmin/hideShowSupportBanner',
    addFaqQuestion: 'websiteAdmin/addFaqQuestion',
    deleteFaqQuestion: 'websiteAdmin/deleteFaqQuestion',
    addSupportIcon: 'websiteAdmin/addSupportIcon',
    deleteSupportIcon: 'websiteAdmin/deleteSupportIcon',
    addSupportVideo: 'websiteAdmin/addSupportVideo',
    deleteSupportVideo: 'websiteAdmin/deleteSupportVideo',
    addSupportGuidePDF: 'websiteAdmin/addSupportGuidePDF',
    updateFaqQuestion: 'websiteAdmin/updateFaqQuestion',
    addSupportText: 'websiteAdmin/addSupportText',
    addSupportSpecification: 'websiteAdmin/addSupportSpecification',


     /*Company Page */
     getCompanyPage: 'websiteAdmin/getCompanyPage',
     addCompanyBanner: 'websiteAdmin/addCompanyBanner',
     hideShowCompanyBanner: 'websiteAdmin/hideShowCompanyBanner',
     addCompanyText: 'websiteAdmin/addCompanyText',
     addCompanyIcon: 'websiteAdmin/addCompanyIcon',
     deleteCompanyIcon: 'websiteAdmin/deleteCompanyIcon',
     addManagement: 'websiteAdmin/addManagement',
     deleteManagementIcon: 'websiteAdmin/deleteManagementIcon',
     addDirector: 'websiteAdmin/addDirector',
     addExecutive: 'websiteAdmin/addExecutive',
     
     /*Patient Page */
     getPatientPage: 'websiteAdmin/getPatientPage',
     addPatientBanner: 'websiteAdmin/addPatientBanner',
     addPatientText: 'websiteAdmin/addPatientText',
     hideShowPatientBanner : 'websiteAdmin/hideShowPatientBanner',
     addPatientIcon: 'websiteAdmin/addPatientIcon',
     deletePatientIcon: 'websiteAdmin/deletePatientIcon',
     addPatientDiabetesIcon: 'websiteAdmin/addPatientDiabetesIcon',
     deletePatientDiabetsIcon: 'websiteAdmin/deletePatientDiabetsIcon',
     addPatientMonitoringIcon: 'websiteAdmin/addPatientMonitoringIcon',
     deletePatientMonitoringIcon: 'websiteAdmin/deletePatientMonitoringIcon',
     addPatientRiskIcon: 'websiteAdmin/addPatientRiskIcon',
     deletePatientRiskIcon: 'websiteAdmin/deletePatientRiskIcon',



    /*Healthcare Page */
    getHealthPage: 'websiteAdmin/getHealthPage',
    addHealthBanner:'websiteAdmin/addHealthBanner',
    hideShowHealthBanner:'websiteAdmin/hideShowHealthBanner',
    addHealthIcon:'websiteAdmin/addHealthIcon',
    addHealthText:'websiteAdmin/addHealthText',
    addHealthLogo:'websiteAdmin/addHealthLogo',
    addHealthBarrier: 'websiteAdmin/addHealthBarrier',
    addHealthConcentration: 'websiteAdmin/addHealthConcentration',
    addHealthPDF: 'websiteAdmin/addHealthPDF',
    addHealthPublicationPDF:'websiteAdmin/addHealthPublicationPDF',
    forgotpassword: 'websiteAdmin/forgotPassword',
    changePassword: 'websiteAdmin/changePassword',
    deleteHealthPublicationPDF: 'websiteAdmin/deleteHealthPublicationPDF',

    /* User Poi Data */
    getPoi: 'user/get-all-poi',
    createPoi: 'user/create-poi',
    updatePoi: 'user/update-poi',
    deletePoi: 'user/delete-poi',
    getpoibyid: '/user/get-poi-details/{poi_id}',
    deleteFile: '/user/delete-file',

    /*  FOOTER SECTION */
    getFooterPage : 'websiteAdmin/getFooterPage',
    addFooterLeft : 'websiteAdmin/addFooterLeft',
    addFooterRight : 'websiteAdmin/addFooterRight',
    addFooterLink: 'websiteAdmin/addFooterLink',
    updateFooterLink: 'websiteAdmin/updateFooterLink',
    deleteFooterLink: 'websiteAdmin/deleteFooterLink',
    

    /* Other Page */
    registration: 'users/registerUser',    
   // forgotpassword: 'users/forgotPassword',
    forgotUserId: 'users/forgotUserId',
    emailAlreadyExist: 'users/checkEmailAlreadyExists',
    updatePassword: 'users/changePassword',
    userEdit: 'users/editUser',
    imageEdit: 'users/updateProfileImage',
    contactUs: 'users/ContactUs',
    dashboard: 'users/DashboardReport',
    snapshot: 'users/SnapShot',
    reportWithinTarget: 'users/ReportWithinTarget',
    dailyAverageReport: 'users/graphDailyAverageReport',
    logbook: 'users/logAllMeasurementReport',
    logbookCurrentDay: '/users/logMeasurementReport',
    dailyMeasurementReport: 'users/graphDailyMeasurementReport',
    standardDay: 'users/graphStandardDayReport',
    defaultSetting:  'users/defaultSetting',
    getdefaultSetting:  'users/getdefaultSetting',
    aboutUs:  'users/getAboutUs',
    privacyPolicy:  'users/getPrivacyPolicy',
    feedback:'users/addFeedback',
    shareReport:'users/sharePDF',
    getMainIdPecId:'users/getMainIdPecId'
    /* 
    chartReport: 'users/ChartReport',
    '*/
};
