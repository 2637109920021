import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute, NavigationEnd} from "@angular/router";


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  userData:any;
  constructor(private router: Router, private actRoute: ActivatedRoute ) {
    console.log("header url "+this.actRoute)
    console.log( "url "+window.location.href);
    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        const loggedInData  = localStorage.getItem('userData');
        this.userData = loggedInData ? JSON.parse(loggedInData) : null;
      }
      
    });

   }

  ngOnInit() {
    
  }

  onLogin(){
    this.router.navigate(['login'])
  }
  logout(){
     // localStorage.clear();
      this.router.navigate(['/login']);
  }
}
