import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl, ValidatorFn } from '@angular/forms';
import { ServicesService } from '../core/Services/services.service';
import { ApiUrl } from '../core/api.url';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CustomPopUpComponent } from '../custom-pop-up/custom-pop-up.component';
import { CookieService } from 'ngx-cookie-service';
import { Location } from '@angular/common';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  submitted;
  loginForm: FormGroup;
  checkboxform: FormGroup;
  ishide = true;
  isLogin: any; isShow;
  modalOptions: NgbModalOptions;
  rememberMe = false;
  modal_message: string = '';
  loogedInUser: any;
  userData: any;
  name: any;
  testData = {
    inputData: ''
  }
  changePData = {}
  loginData = {
    'email': '',
    'password': ''
  }
  data: [];
  errormessage;
  successmessage: '';
  rchecked: 'checked';
  constructor(private route: ActivatedRoute, private formBuilder: FormBuilder, private LoginService: ServicesService, private router: Router,
    private modalService: NgbModal, private cookie: CookieService, private location: Location) {
    if (window.location.href.indexOf('login') == -1) {
      this.router.navigate(['/home']);
    } else {
      this.createloginForm();
    }
  }

  createloginForm() {
    let remail = localStorage.getItem('rememberMeEmail');
    let rpwd = localStorage.getItem('rememberMePassword');

    this.loginForm = this.formBuilder.group({
      email: [remail],
      password: [rpwd]
    })
  }
 
  viewPassword() {
    let toggle = document.getElementsByTagName("input")[1];
    if (toggle.type == "password") {
      toggle.type = "text";
      this.ishide = false;
    }
    else {
      toggle.type = "password";
      this.ishide = true;
    }
  }

  ngOnInit() {
    if (localStorage.getItem('userData')) {
      this.router.navigate(['/dashboard']);
    }
    const isCookieExist: boolean = this.cookie.check('MyCookie');
    if (isCookieExist) {
      this.isShow = false;
    }
    else {
      this.isShow = true;
    }
  }

  get loginControl() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.errormessage = '';
    this.loginData.email = this.loginForm.value.email;
    this.loginData.password = this.loginForm.value.password;

    if (this.loginForm.value.email == null || this.loginForm.value.email == '') {
      this.errormessage = 'Please fill Username';
    }
    else if (this.loginForm.value.password == null || this.loginForm.value.password == '') {
      this.errormessage = 'Please fill Password';
    }
    else if (this.loginForm.value.password != null && this.loginForm.value.email != null) {
      this.LoginService.postData(this.loginData, ApiUrl.login).subscribe(responseData => {
        if (responseData.status == true) {
          localStorage.setItem('userData', JSON.stringify(responseData));
          this.router.navigate(['/dashboard']);
        }
        else {
          this.errormessage = 'Please fill valid Username & Password';
        }
      });
    }
  }


  forgotpwd() {
    this.submitted = true;
    //  if (this.forgotpasswordForm.valid) 
    {
      this.LoginService.postData('', ApiUrl.forgotpassword).subscribe(responseData => {
        let titles = 'Forgot Password';
        this.data = responseData;
        this.openSucess(titles, 'Password sent successfully!');
        this.submitted = false;
      })
      return;
    }
  }

  openSucess(titles, messages) {
    const modalRef = this.modalService.open(CustomPopUpComponent, this.modalOptions);
    modalRef.componentInstance.my_modal_title = titles;
    this.modal_message = `<h5 class="text-center mb-3">` + messages + `</h5>`;
    modalRef.componentInstance.my_modal_message = this.modal_message;
    modalRef.componentInstance.buttons = [{
      text: 'OK',
      class: 'forget-butn-try',
      onClick: () => {
        modalRef.close();
      }
    }]
  }

}