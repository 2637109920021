import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RegistertionComponent} from './registertion.component'
import { ReactiveFormsModule,FormsModule } from '@angular/forms';  
import {RegistertionRoutingModule } from './Registertion-routing.module'
import {NgSelect2Module} from 'ng-select2';
import { SharedModule } from '../shared/shared.module';



@NgModule({
  declarations: [
    RegistertionComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RegistertionRoutingModule,
    NgSelect2Module,
    SharedModule
    
  ]
})
export class RegistertionModule { }
