import { Component, HostListener } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'GPS Storyteller for iPhone';
  userActivity;
  userInactive: Subject<any> = new Subject();
  constructor(private router: Router, private avtivateRoute: ActivatedRoute) {
    // let url = this.avtivateRoute.snapshot.queryParamMap.get('url');
    // if (!url) {
    //   router.navigate(['/home']);
    // }
    this.setTimeout();
    this.userInactive.subscribe(() => this.logout());
  }

  logout() {
    // localStorage.setItem('rememberMeEmail', '');
    //localStorage.clear();
    localStorage.removeItem('userData');
    this.router.navigate(['/home']);
  }
  ngOnInit() { }

  setTimeout() {
    this.userActivity = setTimeout(() => this.userInactive.next(undefined), 54000000);  // in seconds
  }

  @HostListener('window:mousemove') refreshUserState() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }
}

