import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AppComponent } from './app.component';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ServicesService } from './core/Services/services.service';
import { CustomPopUpComponent } from './custom-pop-up/custom-pop-up.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Interceptor } from './core/interceptors/interceptor';
import { LoginModule } from './login/login.module';
import { RegistertionModule } from './registertion/registertion.module';
import { GlucotrackModule } from './glucotrack/glucotrack.module';
import { LayoutModule } from './layout/layout.module';
import { ForgotpasswordModule } from './forgotpassword/forgotpassword.module'
import { NgSelect2Module } from 'ng-select2';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CookieService } from 'ngx-cookie-service';
import { LocalService } from './core/Services/local.service';
import { StorageService } from './core/Services/storage.services';
import { HomeModule } from './home/home.module';
import { IndexModule } from './index/index.module';
import { CustomPoiModalComponent } from './custom-poi-modal/custom-poi-modal.component';
import { CustomDeletePoiModalComponent } from './custom-delete-poi-modal/custom-delete-poi-modal.component';
// import { ViewPoiComponent } from './view-poi/view-poi.component';
// import { AddPoiComponent } from './add-poi/add-poi.component';
// import { GpsPoiComponent } from './gps-poi/gps-poi.component';



@NgModule({
  declarations: [
    AppComponent,
    CustomPopUpComponent,
    CustomPoiModalComponent,
    CustomDeletePoiModalComponent,
    // ViewPoiComponent,
    // AddPoiComponent,
    // GpsPoiComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    NgSelect2Module,
    LoginModule,
    HomeModule,
    IndexModule,
    RegistertionModule,
    GlucotrackModule,
    ForgotpasswordModule,
    LayoutModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
    RouterModule.forRoot([]),

  ],
  entryComponents: [
    CustomPopUpComponent,
    CustomPoiModalComponent,
    CustomDeletePoiModalComponent
  ],
  providers:
    [
      ServicesService,CookieService, LocalService, StorageService,
      { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true },
      { provide: LocationStrategy, useClass: HashLocationStrategy }
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http)
}
