import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ServicesService } from '../core/Services/services.service';
import { ApiUrl } from '../core/api.url';
import { ActivatedRoute,Router } from '@angular/router';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit {
  forgotpasswordForm: FormGroup; 
  resetpasswordForm: FormGroup;
  errormessage:any;  successmessage:any; tokens; showForgot = true;
  ValidData = { email: '' }
  ResetData = { password:'', confirm_password:'', token:'' }
  data: any;
  constructor(private formBuilder:FormBuilder,private PasswordService:ServicesService,private route:ActivatedRoute,private router:Router) 
  {
    this.forgotpasswordForm = this.formBuilder.group({
      email: ['']
    });
    this.resetpasswordForm = this.formBuilder.group({
      password:[''], confirm_password:['']
    });

    this.route.paramMap.subscribe(params => 
    { 
      if(params.get('token'))
      {
        this.tokens = params.get('token');
        this.showForgot = false;        
      }  
      else
      {
        this.showForgot = true;
      }  
    }); 
  } 

  ngOnInit() { }

  get forgotcontrol() 
  {
    return this.forgotpasswordForm.controls;
  }
  onResetSubmit()
  {
    this.errormessage = this.successmessage = '';
    this.ResetData.password = this.resetpasswordForm.value.password;
    this.ResetData.confirm_password = this.resetpasswordForm.value.confirm_password;
    this.ResetData.token = this.tokens;
    if(this.resetpasswordForm.value.password=='')  
    {
      this.errormessage = 'Please fill Password';
    }
    else if(this.resetpasswordForm.value.confirm_password=='')
    {
      this.errormessage = 'Please fill Confirm Password';
    }
    else if(this.resetpasswordForm.value.confirm_password != this.resetpasswordForm.value.password)
    {
      this.errormessage = 'Password and Confirm Password do not match';
    }
    else
    {
      this.PasswordService.postData(this.ResetData,ApiUrl.reset_password).subscribe(responseData => 
      {
        if(responseData.status==true)
        {
          this.successmessage = "Password has been changed successfully";
          setInterval(()=>{  this.router.navigate(['/login']);  },2000);          
        }
        else
        {
          this.errormessage = 'Please try again';
        }
      });
    }    
  }
  onSubmit() 
  {
    this.errormessage = this.successmessage = '';
    if(this.forgotpasswordForm.value.email=='')  
    {
      this.errormessage = 'Please fill email';
    }  
    else
    { 
      this.ValidData.email = this.forgotpasswordForm.value.email;  
      this.PasswordService.postData( this.ValidData,ApiUrl.forget_password).subscribe(responseData => 
      {
        if(responseData.status==403)
          this.errormessage = "Email doesn't exist";
        else if(responseData.status==400)
          this.errormessage = "Email doesn't exist";
        else
          this.successmessage = "Reset Password Link has been sent on your Email Id";
      });    
    }
  }
}

