import { Pipe, PipeTransform } from '@angular/core'; 
import { DomSanitizer } from '@angular/platform-browser'; 
@Pipe({ name: 'sanitizeHtml' }) 

export class SanitizePipe implements PipeTransform {
 /* byPassSecurityTrustHtml(arg0: string, arg1:string): any {
    throw new Error("Method not implemented.");
  } 
    */
    constructor  (private sanitizer: DomSanitizer) { } 
   

transform(value: any, type: any): any {
    
  let data = null;
    //console.log('hi', value)
    if(type === 'meal') {

     data = value === 1 ? `Snack <span class="mx-3"><img src="assets/images/fist.png" class="sm-img" /></span>` :  (value === 2 ? `Light Meal <span class="mx-3"><img src="assets/images/fist.png" class="sm-img" /></span>
      <span class="mr-3"><img src="assets/images/fist.png" class="sm-img" /></span>` : (value === 3 ? `Full Meal <span class="mx-3"><img src="assets/images/fist.png" class="sm-img" /></span>
      <span class="mr-3"><img src="assets/images/fist.png" class="sm-img" /></span>  <span class="mr-3"><img src="assets/images/fist.png" class="sm-img" /></span>`: ''));
    } 
else if (type === 'carb') {

  data = value === 1 ? `Few Carbs <span class="ml-3"><img src="assets/images/few_carbs.png" class="sm-img" /></span>` : 
   (value === 2 ? `Half Carbs <span class="ml-3"><img src="assets/images/half_carbs.png" class="sm-img" /></span>` : 
   (value === 2 ? `Mostly Carbs <span class="ml-3"><img src="assets/images/mostly_carbs.png" class="sm-img" /></span>
  `: ''));
 } else if (type === 'fasting') {

  data = value === 0 ? `<span class="mx-3"><img src="assets/images/Orion_apple.png" class="sm-img" /></span>` : 
   (value === 1 ? ` <span class="mx-3"><img src="assets/images/sun.png" class="sm-img1" /></span>` : 
   (value === 2 ? ` <span class="mx-3"><img src="assets/images/half_apple.png" class="sm-img" /></span>` :
   (value === 3 ? ` <span class="mx-3"><img src="assets/images/timeline.svg" class="sm-img" /></span>` : '')));
 }


  
                                                  
    return this.sanitizer.bypassSecurityTrustHtml(data);
 }
 

}

