import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from '../home/home.component';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { NosidebarLayoutComponent } from './nosidebar-layout/nosidebar-layout.component';
//import { NosidebarHeaderLayoutComponent } from './nosidebarHeader-layout/nosidebarHeader-layout.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      { path: 'dashboard', loadChildren: () => import('../landingPage/landingPage.module').then(m => m.LandingPageModule), data: { footer: true } },
      { path: 'glucotrack', loadChildren: () => import('../glucotrack/glucotrack.module').then(m => m.GlucotrackModule), data: { footer: true } },
      { path: 'healthcare', loadChildren: () => import('../healthcare/healthcare.module').then(m => m.HealthcareModule), data: { footer: true } },
      { path: 'patients', loadChildren: () => import('../patient/patient.module').then(m => m.PatientModule), data: { footer: true } },
      { path: 'resetpassword', loadChildren: () => import('../support/support.module').then(m => m.SupportModule), data: { footer: true } },
      { path: 'gps_poi', loadChildren: () => import('../gps-poi/gps-poi.module').then(m => m.GpsPoiModule), data: { footer: true } },
      { path: 'add_poi', loadChildren: () => import('../add-poi/addpoi.module').then(m => m.AddPoiModule), data: { footer: true } },
      { path: 'showdetail', loadChildren: () => import('../company/company.module').then(m => m.CompanyModule), data: { footer: true } },
      { path: 'showpoi', loadChildren: () => import('../view-poi/viewPoi.module').then(m => m.ViewPoiModule), data: { footer: true } },
    ]
  },
  {
    path: '',
    component: NosidebarLayoutComponent,
    children: [
      { path: 'home', loadChildren: () => import('../home/home.module').then(m => m.HomeModule), data: { footer: false } },
      { path: 'login', loadChildren: () => import('../login/login.module').then(m => m.LoginModule), data: { footer: false } },
      { path: 'registration', loadChildren: () => import('../registertion/registertion.module').then(m => m.RegistertionModule) },
      { path: 'forgotpassword', loadChildren: () => import('../forgotpassword/forgotpassword.module').then(m => m.ForgotpasswordModule), data: { footer: false } },
      { path: 'index', loadChildren: () => import('../index/index.module').then(m => m.IndexModule), data: { footer: false } },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LayoutRoutingModule { }