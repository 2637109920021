import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';  
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { LayoutRoutingModule } from './layout-routing.module';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { NosidebarLayoutComponent } from './nosidebar-layout/nosidebar-layout.component';
import {NosidebarHeaderLayoutComponent} from './nosidebarHeader-layout/nosidebarHeader-layout.component'
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [HeaderComponent, 
    FooterComponent, SidebarComponent, MainLayoutComponent, NosidebarLayoutComponent, NosidebarHeaderLayoutComponent
    ],
  imports: [
    CommonModule,
    RouterModule,
    LayoutRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule
  ],
  exports: [
    FooterComponent,
    HeaderComponent,
    SidebarComponent
  ],
  entryComponents: [
 ],
})
export class LayoutModule { }
