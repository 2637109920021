import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private router: Router, private avtivateRout: ActivatedRoute) {
    if (localStorage.getItem('userData')) {
      this.router.navigate(['/dashboard']);
    }
  }

  ngOnInit() {
  }

  redirectoIndex() {
    // this.router.navigateByUrl('/index?url=gpsstoryteller.mobile://243');
  }
  redirectToAppStore() {
    window.location.href = "https://apps.apple.com/us/app/gps-storyteller/id1567635280";
  }


}
