import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { map, catchError } from "rxjs/operators";
import * as CryptoJs from 'crypto-js';

@Injectable({
providedIn: 'root'
})
export class ServicesService { 
  httpBack: HttpClient;
  token: any;
  userData: any;
constructor( private requestData:HttpClient, private httpbackend:HttpBackend) {
  this.httpBack = new HttpClient(httpbackend);
  let localData:any = localStorage.getItem('userData');
  this.userData = JSON.parse(localStorage.getItem('userData'));
  // this.token = this.userData.result.gpstoken;
  //console.log("Admin: " + this.userData.result.token);
 // console.log("GPS token: " + this.userData.result.gpstoken);
 }
postData(data, url): Observable<any> 
{
  const apiUrl = `${environment.apiBaseUrl}${url}`;  
  return this.requestData.post(apiUrl,data,{headers: {'Content-Type': 'application/json'}});
}
postPoiData(url,data,isHttpInterceptor?): Observable<any> 
{
  const apiUrl = `${environment.apiBaseUrl}${url}`;
  if(isHttpInterceptor){
    return this.httpBack.post(apiUrl,data,{headers: {'Authorization': this.userData.result.gpstoken}})
  }else{
    return this.requestData.post(apiUrl,data,{headers: {'Content-Type': 'application/json'}});
  } 
}
putData(data,url, param?: any): Observable<any> 
{ 
  if(data){
  }  
  const apiUrl = `${environment.apiBaseUrl}${url}`;
  return this.requestData.put(apiUrl,data,{headers: {'Content-Type': 'application/json'}});
}

putPoiData(url,data,isHttpInterceptor?): Observable<any> 
{ 
  // debugger
  const apiUrl = `${environment.apiBaseUrl}${url}`;
  if(isHttpInterceptor){
    console.log('token',this.userData.result.gpstoken)
    return this.httpBack.put<any>(apiUrl,data,{headers: {'Authorization': this.userData.result.gpstoken}})
  }else{
    return this.requestData.put(apiUrl,data,{headers: {'Content-Type': 'application/json'}});
  }
  
}
getData(url,isHttpInterceptor?): Observable<any>
{
  const apiUrl = `${environment.apiBaseUrl}${url}`;
  if(isHttpInterceptor){
    return this.httpBack.get(apiUrl,{headers: {'Content-Type': 'application/json','Authorization': this.userData.result.gpstoken,'page':'1','latitude':'20.22','longitude':'30.22','radius':'20','filter_with':'new_poi','poi_type':'gps'}})
  }else{
    return this.requestData.get(apiUrl,{headers: {'Content-Type': 'application/json'}})
  }
}
deleteData(id,url,isHttpInterceptor?): Observable<any> 
{
  const apiUrl = `${environment.apiBaseUrl}${url}`;  
  const options = {
    headers: new HttpHeaders({
      'Authorization': this.userData.result.gpstoken,
    }),
    body: {
      'poi_id': id
    },
  };
  if(isHttpInterceptor){
    return this.httpBack.delete(apiUrl,options)
  }else{
    return this.requestData.delete(apiUrl,{headers: {'Content-Type': 'application/json'}})
  }
}

deleteDataAdmin(id,url,isHttpInterceptor?): Observable<any> 
{
  const apiUrl = `${environment.apiBaseUrl}${url}`;  
  const options = {
    headers: new HttpHeaders({
      'Authorization': this.userData.result.token,
    }),
    body: {
      'move_poi_id': id
    },
  };
  if(isHttpInterceptor){
    return this.httpBack.delete(apiUrl,options)
  }else{
    return this.requestData.delete(apiUrl,{headers: {'Content-Type': 'application/json'}})
  }
}

deleteFileData(id,url,isHttpInterceptor?): Observable<any> 
{
  const apiUrl = `${environment.apiBaseUrl}${url}`;  
  const options = {
    headers: new HttpHeaders({
      'Authorization': this.userData.result.gpstoken,
    }),
    body: {
      'file_id': id
    },
  };
  if(isHttpInterceptor){
    return this.httpBack.delete(apiUrl,options)
  }else{
    return this.requestData.delete(apiUrl,{headers: {'Content-Type': 'application/json'}})
  }
}

appendFormData(data) 
{
  let formData: FormData = new FormData();
  for (let key in data) {
    if (data.hasOwnProperty(key)) {
      
      formData.append(key, data[key]);
    }
  }
  return formData;
}
}
