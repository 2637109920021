import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ForgotpasswordComponent} from './forgotpassword.component'
import { ReactiveFormsModule,FormsModule } from '@angular/forms';  
import {ForgotpasswordRoutingModule } from './forgotpassword-routing.module'
import { SharedModule } from '../shared/shared.module';
//import {NgSelect2Module} from 'ng-select2';



@NgModule({
  declarations: [
    ForgotpasswordComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    ForgotpasswordRoutingModule,
    SharedModule
  //  NgSelect2Module
    
  ]
})
export class ForgotpasswordModule { }
