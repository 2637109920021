import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {

  constructor(private router: Router, private avtivateRoute: ActivatedRoute) { }

  ngOnInit() {
    let url = this.avtivateRoute.snapshot.queryParamMap.get('url');
    if (url) {
      window.location.href = url;
    }
  }

  redirectToAppStore() {
    window.location.href = "https://apps.apple.com/us/app/gps-storyteller/id1567635280";
  }

}
